import axios from './index';
export default {
  // 获取所有
  listAll: () => {
    return axios.get('/auction-sales/listall');
  },

  // 分页查询
  page: (params) => {
    return axios.get('/auction-sales/listpage', { params });
  },

  // // 根据ID获取
  // getById: (id) => {
  //   return axios.get(`/auction-sales/${id}`);
  // },

  // 新增
  create: (data) => {
    return axios.post('/auction-sales', data);
  },

  // 编辑
  update: (data) => {
    return axios.put('/auction-sales', data);
  },

  // 删除
  remove: (id) => {
    return axios.delete(`/auction-sales/${id}`);
  },

  
  // 导入
  import: (data) => {
    return axios.post('/files/batchInsert', data);
  },
};

<template>
  <div class="layout-main">
    <div class="layout-table-basic">
      <div class="toolbar">
        <div class="search">
          <el-form :inline="true">
            <el-form-item label="投标人昵称/手机号 ：">
              <el-input size="small" placeholder="请输入关键字" v-model="filterData.userKeyword" clearable></el-input>
            </el-form-item>
            <el-form-item label="车辆ID/车型/车牌号 ：">
              <el-input size="small" placeholder="请输入关键字" v-model="filterData.carKeyword" clearable></el-input>
            </el-form-item>
            <el-form-item label="所属竞拍 ：">
              <el-select v-model="filterData.salesId">
                <el-option v-for="item in salesOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="竞拍状态 ：">
              <el-select v-model="filterData.bidStatus">
                <el-option v-for="item in bidStatusOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="handleSearchReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="handle">
          <el-button size="small" type="primary" @click="handleExport()">导出</el-button>
        </div>
      </div>

      <div class="table">
        <el-table v-loading="isTableloading" :data="tableData" stripe highlight-current-row style="width: 100%" height="100%">
          <el-table-column prop="" label="" width="10"> </el-table-column>
          <el-table-column prop="id" label="ID" width="40"> </el-table-column>
          <el-table-column prop="nickname" label="投标人昵称" min-width="100"> </el-table-column>
          <el-table-column prop="tel" label="投标人手机号" width="120"> </el-table-column>
          <el-table-column prop="realname" label="客户姓名" width="80"> </el-table-column>
          <!-- <el-table-column prop="carId" label="车辆ID" width="80"> </el-table-column> -->
          <el-table-column prop="carTitle" label="车辆" min-width="100"> </el-table-column>
          <el-table-column prop="carColor" label="颜色" width="50"> </el-table-column>
          <el-table-column prop="carLicensePlate" label="车牌号" width="90"> </el-table-column>
          <el-table-column prop="salesId" label="所属竞拍" min-width="100">
            <template slot-scope="scope">
              <el-select v-model="scope.row.salesId" disabled>
                <el-option v-for="item in salesOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="bidAmount" label="投标金额" width="100">
            <template slot-scope="scope">
              {{ scope.row.bidAmount }}
              <div class="heightest" v-if="scope.row.isHightest">最高</div>
            </template>
          </el-table-column>
          <el-table-column prop="bidTime" label="投标时间" width="160"> </el-table-column>
          <el-table-column prop="bidStatus" label="竞拍状态" min-width="100">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.bidStatus == 1">进行中</el-tag>
              <el-tag v-if="scope.row.bidStatus == 2" type="warning">待开标</el-tag>
              <el-tag v-if="scope.row.bidStatus == 3" type="success">已中标</el-tag>
              <el-tag v-if="scope.row.bidStatus == 4" type="info">未中标</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要将【' + scope.row.nickname + '】的该条投标记录设置为【中标】吗？'" @confirm="handleWinConfirm(scope.row)" placement="bottom-end">
                <el-button type="text" slot="reference" @click.native.stop="">中标</el-button>
              </el-popconfirm>
              |
              <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要将【' + scope.row.nickname + '】的该条投标记录设置为【未中标】吗？'" @confirm="handleUnbidConfirm(scope.row)" placement="bottom-end">
                <el-button type="text" slot="reference" @click.native.stop="">未中标</el-button>
              </el-popconfirm>
              |
              <el-button @click.native.stop="handleEdit(scope.row)" type="text">编辑金额</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination :page="searchParams.page" :page-size="searchParams.limit" :total="total" background layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>

      <!-- 添加、编辑，模态框组件 -->
      <el-dialog title="编辑投标金额" :visible="dialogVisible" @close="dialogVisible = false" width="600px">
        <el-form :model="form" ref="form" label-width="120px">
          <el-form-item label="请输入新金额" prop="carCode">
            <el-input v-model="form.bidAmount" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSubmit()">保存</el-button>
        </div>
      </el-dialog>

      <!-- 列表详情，抽屉组件 -->
      <el-drawer title="详情" :visible.sync="drawerDetailVisible">
        <div class="layout-drawer-handle">
          <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要将【' + drawerDetailObj.nickname + '】的该条投标记录设置为【中标】吗？'" @confirm="handleWinConfirm(drawerDetailObj)" placement="bottom-end">
            <el-button type="danger" size="small" slot="reference">中标</el-button>
          </el-popconfirm>
        </div>
        <div class="layout-drawer-detail">
          <div class="layout-drawer-detail-top">
            <div class="layout-drawer-detail-top-title">
              基础信息
            </div>
          </div>
          <div class="layout-drawer-detail-main">
            <div class="layout-drawer-detail-list">
              <DrawerDetail liLabel="投标人昵称" :liValue="drawerDetailObj.nickname"></DrawerDetail>
              <DrawerDetail liLabel="投标人手机号" :liValue="drawerDetailObj.tel"></DrawerDetail>
              <DrawerDetail liLabel="车辆ID" :liValue="drawerDetailObj.carId"></DrawerDetail>
              <DrawerDetail liLabel="车辆" :liValue="drawerDetailObj.carTitle"></DrawerDetail>
              <DrawerDetail liLabel="颜色" :liValue="drawerDetailObj.carColor"></DrawerDetail>
              <DrawerDetail liLabel="车牌号" :liValue="drawerDetailObj.carLicensePlate"></DrawerDetail>
              <DrawerDetail liLabel="所属竞拍" :liValue="drawerDetailObj.salesId" :valueArr="this.salesOptions"></DrawerDetail>
              <DrawerDetail liLabel="投标金额" :liValue="drawerDetailObj.bidAmount"></DrawerDetail>
              <DrawerDetail liLabel="投标时间" :liValue="drawerDetailObj.bidTime"></DrawerDetail>
              <DrawerDetail liLabel="竞拍状态" :liValue="drawerDetailObj.bidStatus" :valueArr="this.bidStatusOptions"></DrawerDetail>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Request from '@/api/bid';
import RequestSales from '@/api/sale';

import DrawerDetail from '../../../layout/console/components/DrawerDetail.vue';
export default {
  components: {
    DrawerDetail,
  },

  data() {
    return {
      //列表相关
      tableData: null,
      isTableloading: false,
      total: 0,

      //查询区域字段
      filterData: {
        userKeyword: '',
        carKeyword: '',
        salesId: '',
        bidStatus: '',
      },

      salesOptions: [],

      bidStatusOptions: [
        { label: '进行中', value: 1 },
        { label: '待开标', value: 2 },
        { label: '已中标', value: 3 },
        { label: '未中标', value: 4 },
      ],

      //发送查询请求字段
      searchParams: {
        limit: 10,
        page: 1,
      },

      drawerDetailVisible: false,
      drawerDetailObj: {},

      dialogVisible: false,
      idEdit: '',
      form: {
        bidAmount: '',
      },
    };
  },

  mounted() {
    RequestSales.listAll().then((res) => {
      if (res && res.code === 200) {
        res.data.forEach((e) => {
          this.salesOptions.push({
            label: e.salesTitle,
            value: e.id,
          });
        });
      }
    });

    this.getTableList();
  },

  methods: {
    getTableList() {
      this.isTableloading = true;
      Request.page(this.searchParams).then((res) => {
        console.log(res);
        this.isTableloading = false;
        if (res && res.code === 200) {
          if (res.data.list.length > 0) {
            this.renderTable(res.data.list);
          } else {
            if (this.searchParams.page > 1) {
              this.searchParams.page--;
              this.getTableList();
            } else {
              this.renderTable(res.data.list);
            }
          }
          this.total = res.data.total;
        }
      });
    },

    // 渲染表格
    renderTable(data) {
      this.tableData = [];
      data.forEach((e) => {
        this.tableData.push(e);
      });
    },

    //查询搜索按钮
    handleSearch() {
      for (let key in this.filterData) {
        this.searchParams[key] = this.filterData[key];
      }

      for (let key in this.searchParams) {
        if (!this.searchParams[key]) {
          delete this.searchParams[key];
        }
      }

      this.searchParams.page = 1;
      this.getTableList();
    },

    //查询重置按钮
    handleSearchReset() {
      for (let key in this.filterData) {
        this.filterData[key] = '';
      }
      this.handleSearch();
    },

    //分页改变每页显示数量
    handleSizeChange(val) {
      this.searchParams.page = 1;
      this.searchParams.limit = val;
      this.getTableList();
    },

    //分页跳转至当前页
    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },

    //添加按钮
    handleExport() {
      let parms = {};
      for (let key in this.filterData) {
        if (this.filterData[key]) {
          parms[key] = this.filterData[key];
        }
      }

      Request.excelall(parms).then((res) => {
        window.open(`https://www.xinbaipai.com/api/user-bids/excelall?userKeyword=${this.filterData.userKeyword}&carKeyword=${this.filterData.carKeyword}&salesId=${this.filterData.salesId}&bidStatus=${this.filterData.bidStatus}`);

        // let downloadLoading = this.$loading({ background: 'rgba(0, 0, 0, 0.6)' });
        // const blob = new Blob([res.data], {
        //   type: 'application/vnd.ms-excel;charset=utf-8',
        // });
        // const objectUrl = URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = objectUrl; // 指定下载链接
        // a.download = '投标信息'; // 指定下载文件名
        // a.click();
        // URL.revokeObjectURL(a.href); // 释放URL对象
        // downloadLoading.close();
      });
    },

    //中标
    handleWinConfirm(row) {
      let loading = this.$loading({ background: 'rgba(0, 0, 0, 0.6)' });

      Request.win(row.id).then((res) => {
        if (res && res.code == 200) {
          this.msgSuccess('操作成功');
          loading.close();
          this.getTableList();

          if (this.drawerDetailVisible) this.drawerDetailVisible = false;
        }
      });
    },

    //未中标
    handleUnbidConfirm(row) {
      let loading = this.$loading({ background: 'rgba(0, 0, 0, 0.6)' });

      Request.unbid(row.id).then((res) => {
        if (res && res.code == 200) {
          this.msgSuccess('操作成功');
          loading.close();
          this.getTableList();

          if (this.drawerDetailVisible) this.drawerDetailVisible = false;
        }
      });
    },

    //点击列表row右侧弹出详情框
    handleDrawerDetailVisible(row) {
      this.drawerDetailObj = row;
      this.drawerDetailVisible = true;
    },

    //
    handleEdit(row) {
      this.dialogVisible = true;
      this.idEdit = row.id;
      this.form.bidAmount = row.bidAmount;
    },

    handleSubmit() {
      let data = {
        id: this.idEdit,
        bidAmount: this.form.bidAmount,
      };

      Request.update(data).then((res) => {
        console.log(res);
        if (res && res.code === 200) {
          this.msgSuccess('编辑成功');
          this.getTableList();
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  height: 22px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  color: #606266;
  cursor: initial;
}

::v-deep .el-input.is-disabled .el-input__icon {
  display: none;
}

::v-deep .el-select .el-input.is-disabled .el-input__inner {
  cursor: text;
  background-color: initial;
  border: 0;
  padding-left: 0;
  color: initial;
}
::v-deep .el-input.is-disabled .el-input__icon {
  display: none;
}

.heightest {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  height: 20px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
}
</style>

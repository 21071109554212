import axios from './index';
export default {
  // 获取所有
  listAll: () => {
    return axios.get('/user-bids/listall');
  },

  // 分页查询
  page: (params) => {
    return axios.get('/user-bids/listpage', { params });
  },

  // // 根据ID获取
  // getById: (id) => {
  //   return axios.get(`/user-bids/${id}`);
  // },

  // 新增
  create: (data) => {
    return axios.post('/user-bids', data);
  },

  // 编辑
  update: (data) => {
    return axios.put('/user-bids', data);
  },

  // 删除
  remove: (id) => {
    return axios.delete(`/user-bids/${id}`);
  },

  // 导出
  excelall: (params) => {
    return axios.get('/user-bids/excelall', { params, responseType: 'arraybuffer' });
  },

  // 中标
  win: (id) => {
    return axios.put('/user-bids/status/' + id);
  },

  // 未中标
  unbid: (id) => {
    return axios.put('/user-bids/status/unbid/' + id);
  },
};
